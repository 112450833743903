@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Fira+Sans+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Fira+Sans+Extra+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html {
  font-family: "Fira Sans", system-ui, sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main-container {
  @apply flex flex-col items-center space-y-8 mb-9  md:mb-24 p-4
}

.main-contaier-title {
  @apply text-2xl md:text-3xl font-medium text-center mb-1 md:mb-2
}

.primary-button {
  @apply px-4 py-2 md:px-8 md:py-4 md:text-lg text-white bg-primary rounded-full transition duration-300 ease-in-out;
}

.primary-button:hover {
  @apply bg-primary-dark opacity-80;
}

.primary-button:focus {
  @apply outline-none ring-2 ring-primary-dark;
}

.secondary-button {
  @apply px-4 py-2 md:px-8 md:py-4 md:text-lg text-primary border border-primary rounded-full transition duration-300 ease-in-out;
}

.secondary-button:hover {
  @apply bg-primary text-white opacity-80;
}

.secondary-button:focus {
  @apply outline-none ring-2 ring-primary;
}
